<template>
  <form @submit.prevent="saveItem">
    <b-card>
      <b-row>
        <b-col cols="12" md="6">
          <div class="form-group">
            <label for="redirect">Title:</label>
            <input
              type="text"
              class="form-control"
              v-model="formPayload.other.title"
            />
          </div>
          <div class="form-group">
            <label for="about">About:</label>
            <textarea
              class="form-control"
              v-model="formPayload.other.about"
              style="height: 200px"
              maxlength="500"
            />
            <small style="font-size: 12px"><i>Max: 500</i></small>
          </div>
          <div class="form-group">
            <label for="selection-process">Selection Process:</label>
            <quill-editor
              id="selection-process"
              v-model="formPayload.other.selection_process_html"
            />
          </div>
        </b-col>

        <b-col cols="12" md="6">
          <div class="form-group">
            <label for="file" class="mb-0">Image:</label>
            <b-form-file
              id="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              ref="fileInput"
              accept="image/png, image/jpeg, image/jpg"
              @change="handleFileImage($event)"
            />
            <div v-if="previewImage" class="mt-1 mb-4">
              <img :src="previewImage" class="w-100 product__image rounded" />
            </div>
          </div>

          <div>
            <label>Requirements:</label>
            <b-row
              v-for="(item, index) in formPayload.other.requirements"
              :key="index"
            >
              <b-col cols="11">
                <div class="form-group">
                  <b-form-textarea
                    rows="1"
                    max-rows="6"
                    type="text"
                    class="form-control"
                    v-model="formPayload.other.requirements[index]"
                    maxlength="500"
                  />
                </div>
              </b-col>
              <b-col cols="1">
                <b-button
                  variant="danger"
                  class="btn waves-effect waves-float waves-light btn-danger"
                  style="padding: 6px"
                  @click="removeItem(index)"
                >
                  <trash-icon size="1x" class="custom-class"></trash-icon>
                </b-button>
              </b-col>
            </b-row>
            <b-button
              variant="primary"
              class="btn waves-effect waves-float waves-light btn-primary"
              @click="addForm()"
            >
              Add Requirement
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-col cols="12" class="p-0 mt-2">
      <template v-if="isLoading">
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
          disabled
        >
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </button>
      </template>
      <template v-else>
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          Save
        </button>
      </template>
    </b-col>
  </form>
</template>

<script>
import { TrashIcon } from "vue-feather-icons";
import { quillEditor } from "vue-quill-editor";
import {
  BCard,
  BCardText,
  BForm,
  BButton,
  BCol,
  BRow,
  BSpinner,
  BFormFile,
  BFormTextarea,
} from "bootstrap-vue";
import { successNotification, errorNotification } from "@/auth/utils";
export default {
  components: {
    BCard,
    BCardText,
    BForm,
    BButton,
    BCol,
    BRow,
    BSpinner,
    BFormFile,
    BFormTextarea,
    TrashIcon,
    quillEditor,
  },

  data() {
    return {
      formPayload: {
        type: "female-future-leader",
        other: {
          image: null,
          title: "",
          about: "",
          selection_process_html: "",
          requirements: [],
        },
      },
      testingBenefit: null,
      testingProcedure: null,
      previewImage: null,
      isLoading: false,
      validations: "",
    };
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  methods: {
    getData() {
      this.isLoading = true;
      this.$http
        .get("/api/v1/admin/page/female-future-leader")
        .then((response) => {
          this.formPayload = response.data.data;
          if (this.formPayload.other.image !== null) {
            this.previewImage = this.formPayload.other.image;
          }
          this.isLoading = false;
        });
    },
    addForm() {
      this.formPayload.other.requirements.push("");
    },
    removeItem(index) {
      this.formPayload.other.requirements.splice(index, 1);
    },
    handleFileImage(event) {
      const file = event.target.files[0];
      this.formPayload.other.image = file;
      this.showImage(event.target.files[0]);
    },
    showImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    saveItem() {
      this.isLoading = true;
      const payload = new FormData();
      payload.append("type", this.formPayload.type);
      payload.append("other[title]", this.formPayload.other.title);
      payload.append("other[about]", this.formPayload.other.about);
      payload.append("other[image]", this.formPayload.other.image);
      payload.append("other[selection_process_html]", this.formPayload.other.selection_process_html);
      this.formPayload.other.requirements.forEach((term, index) => {
        payload.append(`other[requirements][${index}]`, term);
      });
      this.$http
        .post(`/api/v1/admin/page/female-future-leader`, payload, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          successNotification(this, "Success", "Female Future Leader Berhasil di update!");
          this.isLoading = false;
          this.getData();
        })
        .catch((error) => {
          if (error.response.data.meta.messages) {
            errorNotification(this, "Error", error.response.data.meta.messages);
          } else if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
.product__icon {
  width: 24px;
  height: 24px;
}
.product__image {
  height: 300px;
  object-fit: contain;
}
</style>
